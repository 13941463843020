import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import { RootState } from '@reducers/index'

import AdminTemplate from '@templates/AdminTemplate'

import CodePage from '@pages/CodePage'
import NotFoundPage from '@pages/Error/NotFoundPage'
import TestPage from '@pages/Test'

import WebPopUp from '@components/molecules/PopupModals/WebPopUp'
import FullPageLoading from '@components/organisms/FullPageLoading'

import BranchRouter from './BranchRouter'
import HqRouter from './HqRouter'

const EVENT = {
  NAME: 'nationalTaxService2Nov',
  START_DATE: '2024-10-29 00:00:00',
  END_DATE: '2024-11-04 09:00:00',
  BLOCK_PERIOD: 7,
  PATH: '#',
  IMAGE: '/images/popup/gadjet_2novupdate_modal.png',
  WIDTH: '370px',
  HEIGHT: '500px',
}

export default function AuthorizedRoutes(): JSX.Element {
  const { loading, indexPath } = useSelector((state: RootState) => state.menu)

  const [isVisiblePopUp, setIsVisiblePopUp] = useState(false)

  useEffect(() => {
    const expiry: string | null = localStorage.getItem(EVENT.NAME)
    const now: string = dayjs().format('YYYY/MM/DD HH:mm:ss')
    const startPoint: string = dayjs(EVENT.START_DATE).format('YYYY/MM/DD HH:mm:ss')
    const endPoint: string = dayjs(EVENT.END_DATE).format('YYYY/MM/DD HH:mm:ss')

    if (now < startPoint || now > endPoint) {
      setIsVisiblePopUp(false)
      localStorage.removeItem(EVENT.NAME)
    } else {
      if (!expiry || expiry < now) setIsVisiblePopUp(true)
      if (expiry && expiry > now) setIsVisiblePopUp(false)
    }
  }, [])

  if (loading) return <FullPageLoading />

  return (
    <>
      <Route exact path="/">
        <Redirect path="*" push to={indexPath} />
      </Route>
      <Route exact path="/code">
        <CodePage />
      </Route>
      <Route exact path="/test">
        <TestPage />
      </Route>
      <Route path={['/hq/:hqId/branch/:branchId', '/hq/:hqId', '*']}>
        <AdminTemplate>
          {isVisiblePopUp && (
            <WebPopUp
              name={EVENT.NAME}
              blockPeriod={EVENT.BLOCK_PERIOD}
              path={EVENT.PATH}
              image={EVENT.IMAGE}
              width={EVENT.WIDTH}
              height={EVENT.HEIGHT}
            />
          )}
          <Switch>
            <Route path="/hq/:hqId/branch/:branchId">
              <BranchRouter />
            </Route>
            <Route path="/hq/:hqId">
              <HqRouter />
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </AdminTemplate>
      </Route>
    </>
  )
}
